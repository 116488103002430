import type { PhoneCode } from '#types/config/phoneCode'

/**
 * @param phoneCode
 * @param targetCountryCode Disambiguate between multiple areas with the same dial code
 * @returns {string} Country Code
 */
export const getCountryCodeByPhoneCode = (phoneCode: string, targetCountryCode?: string) => {
  const phoneCodes = useRuntimeConfig().public.phoneCodes as PhoneCode[]

  if (targetCountryCode) {
    const targetCode = phoneCodes.find(({ code }) => code === targetCountryCode)

    if (targetCode?.dialCode === phoneCode)
      return targetCountryCode
  }

  return phoneCodes.find(({ dialCode }) => dialCode === phoneCode)?.code
}
